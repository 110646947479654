import { render, staticRenderFns } from "./DeleteWineModal.vue?vue&type=template&id=1159d16c&scoped=true"
import script from "./DeleteWineModal.vue?vue&type=script&lang=js"
export * from "./DeleteWineModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1159d16c",
  null
  
)

export default component.exports